<template>
  <div class="accordion-body collapse" :class="{show: !collapsed}">
    <div class="accordion-inner" :class="{'p-0': noPadding, 'border-top-0': noTopBorder}">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccordionItemBody",
  props: {
    collapsed: {
      type: Boolean,
      default: true,
    },
    noPadding: Boolean,
    noTopBorder: Boolean,
  }
}
</script>
