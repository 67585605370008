<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ title }}</ion-title>
      <ion-buttons slot="end">
        <ion-button v-if="!isDesktop" @click="modalClose">Close</ion-button>
        <a v-else href="javascript:void(0);" class="close text-secondary" @click="modalClose"><nio-icon icon="ni-cross-sm"></nio-icon></a>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding" :style="{background: contentBgColor}">{{content}}</ion-content>
</template>

<script>
import {IonHeader, IonTitle, IonContent, isPlatform, modalController} from "@ionic/vue";

export default {
  components:{IonHeader, IonTitle, IonContent},
  props:{
    content: null,
    contentBgColor: {
      type: String,
      default: 'var(--background);'
    },
    title: null,
  },
  setup(){
    const isDesktop = isPlatform('desktop')
    const modalClose = async ()=>{
      await modalController.dismiss()
    }
    return{
      isDesktop,
      modalClose,
    }
  }
}
</script>
