<template>
  <a href="#" class="accordion-head" data-toggle="collapse" :class="{collapsed: collapsed}">
    <h6 class="title mt-0">
      <slot></slot>
    </h6>
    <span class="accordion-icon"></span>
  </a>
</template>

<script>
export default {
  name: "AccordionItemHead",
  props: {
    collapsed: {
      type: Boolean,
      default: true,
    },
  }
}
</script>
