<template>
  <row class="gy-3 py-1">
    <column default="12">
      <form-group>
        <label class="overline-title" for="event_title">{{ $t('Event Title') }}</label>
        <div class="form-control-wrap">
          <input type="text" class="form-control" id="event_title" v-model="eventData.title"/>
        </div>
      </form-group>
    </column>
    <column default="6">
      <label class="overline-title">{{ $t('Start Date & Time') }}</label>
      <ion-datetime
          v-if="platform === 'mobile'"
          display-format="DD/MM/YYYY HH:mm"
          picker-format="DD/MM/YYYY HH:mm" class="form-control"
          :value="eventData.start_time"
          v-model="eventData.start_time">

      </ion-datetime>
      <vue-flatpickr v-else v-model="eventData.start_time" id="d_range_f" class="form-control" :config="vueFlatPickerConfig"></vue-flatpickr>
    </column>
    <column default="6">
      <label class="overline-title">{{ $t('End Date & Time') }}</label>
      <ion-datetime
          v-if="platform === 'mobile'"
          display-format="DD/MM/YYYY HH:mm"
          picker-format="DD/MM/YYYY HH:mm" class="form-control"
          :value="eventData.end_time"
          v-model="eventData.end_time">

      </ion-datetime>
      <vue-flatpickr v-else v-model="eventData.end_time" id="d_range_f" class="form-control" :config="vueFlatPickerConfig"></vue-flatpickr>
    </column>
    <column default="12">
      <form-group>
        <label class="overline-title" for="event_description">{{ $t('Event Description') }}</label>
        <div class="form-control-wrap">
          <textarea class="form-control" id="event_description" v-model="eventData.description"></textarea>
        </div>
      </form-group>
    </column>
  </row>
</template>

<script>
import {Row, Column, FormGroup} from "@core/layouts";
import {reactive, watch} from "vue";
import { IonDatetime } from "@ionic/vue"
import VueFlatpickr from "vue-flatpickr-component"
import 'flatpickr/dist/flatpickr.css';
import useCurrentPlatform from "@/store/currentPlatform"
import {Dutch} from "flatpickr/dist/l10n/nl"
import {useStore} from "vuex";

export default {
  components: {FormGroup, Column, Row, IonDatetime, VueFlatpickr},
  props: {
    title: String,
    startTime: String,
    endTime: String,
    description: String,
  },
  emits: ['eventDataUpdated'],
  setup(props, {emit}){

    const store = useStore()
    const { platform } = useCurrentPlatform()
    let eventData = reactive({
      title: props.title,
      start_time: props.startTime,
      end_time: props.endTime,
      description: props.description,
    })

    const vueFlatPickerConfig = {
      dateFormat: 'Z',
      altFormat: 'd/m/Y H:i',
      altInput: true,
      enableTime: true,
      minDate: new Date(),
      time_24hr: true,
      locale: store.state.appConfig.lang === 'nl' ? Dutch: '',
    }

    watch(eventData, (newV) => {
      emit('eventDataUpdated', newV)
    })

    return {
      eventData,
      platform,
      vueFlatPickerConfig,
    }
  }
}
</script>

<style>
.fc .fc-scrollgrid-liquid{
  height: 500px;
}
.fc .fc-daygrid-day-frame{
  min-height: auto!important;
}
</style>
