<template>
  <div class="accordion-item">
    <slot></slot>
  </div>
</template>

<script>
export default {
}
</script>
