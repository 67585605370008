import {alertController, isPlatform, toastController} from "@ionic/vue";
import {useI18n} from "vue-i18n";

export default () => {

    const{t} = useI18n()
    const isDesktop = isPlatform('desktop')
    const IonSimpleAlert = async (header, message, btnText)=>{
        const iA = await alertController.create({
            cssClass: isDesktop ? 'alert-web' : '',
            header: t(header), message: t(message),
            buttons: [
                {
                    text: t(btnText),
                    cssClass: isDesktop ? 'alert-btn-light' : '',
                },
            ]
        })
        await iA.present()
    }
    const IonToast = async (options)=>{
        const ts = await toastController.create(options)
        await ts.present()
    }

    return {
        IonSimpleAlert,
        IonToast,
    }
}